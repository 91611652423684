<template>
  <div class="maintenance-mode">
    <notification-list-item class="message" data-test="message ja">
      <template #title>お知らせ</template>
      <template #content>{{
        'メンテナンス中です。\n暫くしてから再度お試し下さい。'
      }}</template>
    </notification-list-item>
    <br />
    <notification-list-item class="message" data-test="message en">
      <template #title>Notice</template>
      <template #content>{{
        'PETORELU PLUS is under maintenance.\nPlease try again after a while.'
      }}</template>
    </notification-list-item>
  </div>
</template>

<script>
import NotificationListItem from '@/components/parts/molecules/NotificationListItem'

export default {
  name: 'MaintenanceMode',

  components: {
    NotificationListItem
  }
}
</script>

<style lang="scss" scoped>
.maintenance-mode {
  margin-bottom: 100px;
  > .message {
    margin: 20px;
  }
}
</style>
